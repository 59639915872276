import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
const LoaderApp = ({ status, text }) => {
  return (
    <Dimmer active inverted>
      <Loader inverted>{text ? text : "Loading..."}</Loader>
    </Dimmer>
  );
};

export default LoaderApp;
