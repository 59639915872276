export const API_URL = process.env.REACT_APP_API_SERVER_URL;
export const WSS_URL = process.env.REACT_APP_WSURL;
export const APP_TEMPLATE = process.env.REACT_APP_TEMPLATE;
export const APP_COMPONENT = process.env.REACT_APP_COMPONENT;
export const APP_ENV = process.env.REACT_APP_ENV;

export const cnfApp = {
  map: {
    tokenKey:
      "pk.eyJ1Ijoia2FkaXJlY3RlZCIsImEiOiJjanMyZW1qYmcyM3gzNDNtbG83NnF6MWRiIn0.6AHqB0HW8PFuyL6wNmXd4g",
    style: "mapbox://styles/kadirected/cju7pdmz82faz1flan4df02mf",
    centerLocation: [124.001569, -27.267829],
    zoomLevel: [4.1]
  },
  satelite: {
    tokenKey: "42233G-CAMNDN-KH8P4S-3YEA",
    style: "mapbox://styles/kadirected/cjsb9ggby0q6x1fmvgrvaanza",
    centerLocation: [123.793815, -28.550751],
    zoomLevel: [0],
    timer: 30000,
    NoradIds: [
      { id: 41328 },
      { id: 41019 },
      { id: 40730 },
      { id: 40534 },
      { id: 40294 },
      { id: 40105 },
      { id: 39741 },

      { id: 39533 },
      { id: 39166 },
      { id: 38833 },
      { id: 37753 },
      { id: 36585 },
      { id: 35752 },
      { id: 32711 },
      { id: 32384 },
      { id: 32260 },

      { id: 29601 },
      { id: 29486 },
      { id: 28874 },
      { id: 28474 },
      { id: 28361 },
      { id: 28190 },
      { id: 28129 },
      { id: 27704 },
      { id: 27663 },

      { id: 26605 },
      { id: 26407 },
      { id: 26360 },
      { id: 25933 },
      { id: 24876 },
      { id: 22877 }
    ]
  },
  distance: {
    timer: 10000
  },
  slideTimer: 10000,
  charts: {
    Fueltimer: 10000,
    type: "spline",
    Distancetimer: 10000
  }
};
