import React, { Fragment, lazy, Suspense } from "react";
import { APP_COMPONENT, APP_TEMPLATE } from "./config/config"; //Call Config From .env
import "./scss/_main.scss"; //call main style
import LoaderApp from "./components/general/attributes/LoaderApp";
let MainApp = lazy(() => import(`./components/default/MainApp`)); //Default Component
const templateName = APP_COMPONENT;

/**
 * Main App
 *************************************************************************************************************/
const App = () => {
  /**
   * Local States
   *********************************************************************************************************/
  if (templateName !== "")
    MainApp = lazy(() => import(`./components/${templateName}/MainApp`)); // Component base on APP_COMPONENT

  /**
   * Return Method
   ***********************************************************************************************************/
  return (
    <Fragment>
      <Suspense fallback={<LoaderApp />}>
        <div className={`app_${APP_TEMPLATE}`}>
          <MainApp></MainApp>
        </div>{" "}
      </Suspense>
    </Fragment>
  );
};

export default App;
